@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&amp;display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&amp;display=swap');

/*
* ----------------------------------------------------------------------------------------
* 01. Color
* ----------------------------------------------------------------------------------------
*/
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-theme: 'Ubuntu', sans-serif;
  --font-theme-two: 'Manrope', sans-serif;
  --color-one: #2a862e;
  --color-one-dark: #257629;
  --color-one-rgb: 42, 134, 46;
  --color-two: #ffd900;
  --color-two-dark: #f2ce05;
  --color-two-rgb: 255, 217, 0;
  --color-three: #1372de;
  --color-three-dark: #1168cb;
  --color-three-rgb: 19, 114, 222;
  --color-black: #000000;
  --color-black-rgb: 0, 0, 0;
  --color-white: #ffffff;
  --color-white-rgb: 255, 255, 255;
  --font-bootstrap: bootstrap-icons;
  --font-remixicon: remixicon;
  --transition: all 0.3s;
  --color-four: #f0f7fd;
}


/*scrollbar css start*/

* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-one) transparent;
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--color-one);
}
::selection {
  background: var(--color-one);
}
::selection {
  color: var(--color-white);
  text-shadow: none;
}
:root {
  scroll-behavior: smooth;
}
/*scrollbar css end*/


/*
* ----------------------------------------------------------------------------------------
* 02. Color Classes
* ----------------------------------------------------------------------------------------
*/

.color-one {
  color: var(--color-one) !important;
}

.color-two {
  color: var(--color-two) !important;
}

.color-three {
  color: var(--color-three) !important;
}

.color-black {
  color: var(--color-black) !important;
}

.color-white {
  color: var(--color-white) !important;
}

.bg-one {
  background-color: var(--color-one) !important;
}

.bg-two {
  background-color: var(--color-two) !important;
}

.bg-three {
  background-color: var(--color-three) !important;
}

.bg-black {
  background-color: var(--color-black) !important;
}

.bg-white {
  background-color: var(--color-white) !important;
}

.bg-four {
  background-color: var(--color-four) !important;
}


/*
* ----------------------------------------------------------------------------------------
* 04. Container Width
* ----------------------------------------------------------------------------------------
*/

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1300px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1170px;
  }
}

@media (min-width: 1350px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1220px;
  }
}


/*
* ----------------------------------------------------------------------------------------
* 04. Button Css
* ----------------------------------------------------------------------------------------
*/

.theme-btn,
.theme-btn:focus {
  transition: var(--transition);
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  width: fit-content;
  display: block;
  padding: 13px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-family: var(--font-theme-two);
}

.theme-btn i {
  margin-inline-start: 10px;
}

.theme-btn i.start {
  margin-inline-end: 10px;
  margin-inline-start: 0px;
}

.theme-btn:hover {
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
}

.theme-btn.btn-small{
  padding: 7px 15px;
  font-size: 14px;
}


.theme-btn.width-increase:hover {
  padding: 13px 25px;
}
.theme-btn.width-increase.btn-small:hover {
  padding: 7px 19px;
}


.theme-btn.one,
.theme-btn.one:focus {
  background: var(--color-one);
  color: var(--color-white);
}

.theme-btn.one:hover {
  background: var(--color-one-dark);
}

.theme-btn.two, .theme-btn.two:focus {
  background: var(--color-one);
  color: var(--color-white);
}
.theme-btn.two:hover {
  background: var(--color-one-dark);
}

.theme-btn.three,
.theme-btn.three:focus {
  background: var(--color-one);
  color: var(--color-white);
}

.theme-btn.three:hover {
  background: var(--color-one-dark);
}

.plan-two-card.active .theme-btn.two{
  background: var(--color-two);
  color: var(--color-black);
}

.plan-two-card.active .theme-btn.two:hover{
  background: var(--color-two-dark);
  color: var(--color-black);
}



/*
* ----------------------------------------------------------------------------------------
* 04. Section Gap
* ----------------------------------------------------------------------------------------
*/

.theme-section-gap {
  padding: 60px 0px;
}


.theme-section-heading h2 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 10px;
}

@media (max-width:550px) {
  .theme-section-heading h2{
    font-size: 32px;
  }
}

.theme-section-heading p {
	font-size: 17px;
	font-family: var(--font-theme-two);
	color: #666866;
	line-height: 2;
}

@media (max-width:550px) {
  .theme-section-heading p{
    font-size: 15px;
  }
}

.theme-section-heading .style {
	color: var(--color-white);
	width: fit-content;
	border-radius: 4px;
	background: var(--color-one);
	line-height: 1.5;
	font-weight: 400;
	text-transform: uppercase;
	padding: 5px 10px;
	font-size: 13px;
	margin-bottom: 10px;
	letter-spacing: 0.8px;
	font-family: var(--font-theme-two);
}
.theme-section-heading.center,
.theme-section-heading.center h2,
.theme-section-heading.center p,
.theme-section-heading.center .style {
  text-align: center;
}

.theme-section-heading.center .style {
  margin: auto;
}

.theme-section-gap.bg-img-one{
  background: linear-gradient(45deg, rgba(61, 96, 63, 0.82) 0%, rgba(61, 96, 63, 0.82) 100%),  center center no-repeat;
    background-attachment: scroll, scroll;
    background-size: auto, auto;
  background-size: cover;
}




.highlight {
	font-weight: 700;
	overflow: hidden;
	position: relative;
	z-index: 1;
	/* display: inline-block; */
}

.highlight:after {
  content: '';
  background: var(--color-two);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
  left: 0;
  z-index: -1;
  transition: all 0.2s;
}

.highlight.thirty:after {
  height: 30%;
}

.highlight:hover:after {
  height: 100%;
}


/*
* ----------------------------------------------------------------------------------------
* Top Bar
* ----------------------------------------------------------------------------------------
*/

.topbar{
  position: absolute;
  width: 100%;
  top: 5px;
}

.topbar .top-bar-sale {
	font-size: 16px;
	font-weight: 500;
	font-family: var(--font-theme-two);
	color: #585954;
	letter-spacing: 0.3px;
}

.top-bar-dropdown button {
	font-size: 15px;
	font-weight: 500;
	font-family: var(--font-theme-two);
	color: #686868;
	letter-spacing: 0.5px;
	background: none;
	outline: none;
	border: none;
}



.top-bar-dropdown button:after{
  content: '\EA4E';
  font-family: var(--font-remixicon);
  color: #424242;
  font-size: 16px;
  transition: all 0.3s;
  display: inline-block;
}

.top-bar-dropdown button.show:after{
  transform: rotate(-180deg);
  color: var(--color-one);
}

.top-bar-dropdown button.show{
  color: var(--color-one);
}

.top-bar-dropdowns .top-bar-dropdown + .top-bar-dropdown{
  margin-inline-start: 5px;
}

.top-bar-dropdown.language-dropdown-menu .dropdown-menu{
max-height: 400px;
overflow: auto;
}

.top-bar-dropdown.nofication-dropdown-menu .dropdown-menu{
  min-width: 300px;
}

.topbar.white .top-bar-sale{
color: #fff;
}


.topbar.white .top-bar-dropdown button {
  color: #fff;
}

.topbar.white .top-bar-dropdown button:after{
  color: #fff;
}


.dropdown-menu{
  border: none;
  border-radius: 4px;
  padding: 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.dropdown-menu li + li{
  border-top: 1px solid #cac7c7;
}

.dropdown-menu li a {
  display: block;
	padding: 10px;
	font-size: 14px;
	font-weight: 600;
	font-family: var(--font-theme-two);
	color: #828282;
  transition: all 0.3s;
}
.dropdown-menu li a:hover{
  color: var(--color-one);
}

/*
* ----------------------------------------------------------------------------------------
* Header Style
* ----------------------------------------------------------------------------------------
*/

.theme-main-menu {
	position: absolute;
	width: 100%;
	top: 37px;
	z-index: 5;
	transition: height .5s ease-in;
}

@media (max-width:1200px) {
  .theme-main-menu >.rounded{
    padding: 10px;
  }
}
@media (max-width:550px) {
  .theme-main-menu{
    top: 35px;
  }
  .theme-main-menu >.rounded{
    border-radius: 0px !important;
    padding: 10px;
  }
}



.theme-main-menu.sticky{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: unset;
  background-color: var(--color-white);
  animation: headerSticky .95s ease forwards;
  box-shadow: var(--bs-box-shadow) !important;
  z-index: 99;
  max-width: 100%;
}

@keyframes headerSticky {
  0% {
      transform: translateY(-100%);
  }
  100% {
      transform: translateY(0);
  }
}

.theme-main-menu.sticky > .shadow{
  box-shadow: unset !important;
}



.navbar-toggler {
  width: 44px;
  height: 38px;
  padding: 0;
  box-shadow: none;
  position: relative;
  z-index: 99;
  border: none;
  background: var(--color-one);
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler::before,
.navbar-toggler::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 9px;
  width: 26px;
  height: 2px;
  pointer-events: none;
  -webkit-transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background: #fff;
}

.navbar-toggler span {
  position: absolute;
  left: 9px;
  overflow: hidden;
  width: 26px;
  height: 2px;
  margin-top: -1px;
  text-indent: 200%;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  background: #fff;
}

.navbar-toggler::before {
  -webkit-transform: translate3d(0, -9px, 0) scale3d(1, 1, 1);
  transform: translate3d(0, -9px, 0) scale3d(1, 1, 1);
}

.navbar-toggler::after {
  -webkit-transform: translate3d(0, 8px, 0) scale3d(1, 1, 1);
  transform: translate3d(0, 8px, 0) scale3d(1, 1, 1);
}

.navbar-toggler[aria-expanded="true"] span {
  opacity: 0;
}

.navbar-toggler[aria-expanded="true"]::before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}

.navbar-toggler[aria-expanded="true"]::after {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
}

.theme-menu .navbar-toggler {
  background: #fff;
}

.theme-menu .navbar-toggler::before,
.theme-menu .navbar-toggler::after,
.theme-menu .navbar-toggler span {
  background: var(--color-one);
}

.navbar .navbar-nav .nav-link {
  font-family: var(--font-theme-two);
  font-size: 15px;
  font-weight: 500;
  line-height: initial;
  color: #000;
  padding: 15px 0;
  margin: 0 26px;
  position: relative;
}


.dropdown-icon {
  position: absolute;
  right: 0;
}

@media(min-width:1200px) {
  .navbar .navbar-nav .nav-link:after {
    content: " ";
    position: absolute;
    background: var(--color-one);
    top: 100%;
    margin-top: -7px;
    border-radius: 3px;
    left: 0%;
    width: 100%;
    height: 3px;
    transform: scaleX(0);
    transition: transform 0.2s;
    transform-origin: right center;
    box-sizing: border-box;
    display: block;
  }

  .navbar .navbar-nav .nav-item:hover .nav-link:after,
  .navbar .navbar-nav .nav-item.active .nav-link:hover:after {
    transform: scaleX(1);
    transform-origin: left center;
  }
}

.navbar .navbar-nav .nav-item.active .nav-link,
.navbar .navbar-nav .nav-item:hover .nav-link {
  color: var(--color-one);
}

.navbar .dropdown-menu .dropdown-item {
  font-family: var(--font-theme-two);
  font-size: 15px;
  padding: 0 15px;
  border-radius: 5px;
  letter-spacing: 0px;
  text-transform: capitalize;
  line-height: 40px;
  margin-bottom: 5px;
  color: #00284E;
  background: transparent;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
  white-space: inherit;
  font-weight: 500;
}

.navbar .dropdown-menu .dropdown-item span {
  font-family: var(--font-theme-two);
}

.navbar .dropdown-menu .dropdown-item .tag {
  position: absolute;
  background: var(--red-one);
  line-height: 15px;
  color: #fff;
  font-size: 10px;
  letter-spacing: 1px;
  padding: 0 4px;
  text-transform: uppercase;
  top: 0;
  right: 0;
  font-style: normal;
}

.navbar .dropdown-menu .dropdown:hover>.dropdown-item,
.navbar .dropdown-menu .dropdown-item.active,
.navbar .dropdown-menu .dropdown-item:hover {
  background: var(--color-one);
  color: #fff;
}

.navbar .dropdown-toggle::after {
  content: "\f282";
  font-family: bootstrap-icons;
  border: none !important;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  border-radius: 3px;
  background: var(--color-one);
  right: 0;
  top: calc(50% - 15px);
  text-align: center;
  transition: all 0.3s ease;
}

.navbar .show.dropdown-toggle::after {
  transform: rotate(180deg);
}

.navbar .dropdown-submenu .dropdown-toggle::after {
  content: "\f285";
  font-family: bootstrap-icons !important;
  display: block;
  right: 15px;
  top: 0;
  background: transparent !important;
  color: inherit;
  width: auto;
  height: auto;
  line-height: 40px;
  font-weight: normal;
  font-size: 13px;
  transition: transform 0.3s ease-in-out;
}

.navbar .dropdown-submenu:hover>.dropdown-toggle::after {
  transform: rotate(90deg);
}

.navbar .dropdown-menu li + li{
  border: unset;
}

/*----- For Desktop -------*/
@media screen and (min-width: 1200px) {
  .navbar .dropdown-toggle::after {
    display: none;
  }

  .navbar .dropdown-menu {
    z-index: 5;
    background-color: #fff;
    border-radius: 10px;
    display: block;
    right: 0;
    left: 0;
    padding: 10px;
    border: none;
    top: 100%;
    visibility: hidden;
    transform: scale(1, 0);
    opacity: 0;
    min-width: 250px;
    box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.12);
    margin: 0;
    transform-origin: 0 0;
    transition: all 0.3s ease-in-out;
  }

  .navbar .dropdown-menu .dropdown-menu {
    left: calc(100% + 10px);
    top: 0;
    right: auto;
    min-width: 240px;
    box-shadow: 0 20px 30px -10px rgb(0, 0, 0, 0.15);
    transform: scale(0, 1);
  }

  .navbar .dropdown:hover>.dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

}

.dropdown-item i {
  margin-inline-end: 5px;
}

.logo img {
  width: 80%;
}

.footer-logo img {
  width: 200px
}

/*(min-width: 992px)*/

/*----- For Mobile ----*/
@media screen and (max-width: 1200px) {
  .navbar {
    padding: 0;
  }

  .navbar-collapse .logo {
    margin-bottom: 0vh;
  }

  .navbar-collapse .logo img{
    max-height: 48px;
    width: auto;
  }

  .navbar-collapse {
    position: fixed;
    top: 0;
    background-color: #ffffff;
    left: 0;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    clear: both;
    width: 320px;
    max-width: calc(100vw - 60px);
    z-index: 9999;
    transform: translateX(-100%);
    display: block !important;
    padding: 32px 15px 20px;
    transition: all 0.3s ease-in-out;
  }

  .navbar-collapse.show {
    transform: translateX(0);
    box-shadow: 15px 0 25px rgba(35, 35, 35, 0.1);
  }

  .theme-main-menu .navbar .mega-dropdown {
    position: relative;
  }

  .navbar .navbar-nav .nav-link {
    margin: 0;
    padding: 13px 0;
    border-bottom: 1px solid #f3f3f3;
  }

  .navbar .dropdown-menu .dropdown-item {
    padding: 0 10px;
    font-size: 16px;
  }

  .navbar .dropdown-menu {
    border: none;
    border-bottom: 1px solid #f3f3f3;
    padding: 0;
    border-radius: 0;
    margin: 0;
  }

  .navbar .dropdown-submenu .dropdown-menu {
    margin: 0;
    padding: 0 0 0 15px;
  }

  .theme-menu-four .navbar .navbar-nav .nav-link:before {
    display: none;
  }

  /*menu three*/
  .theme-menu .navbar-collapse {
    background: #6b28ff;
  }

  .theme-menu .navbar .dropdown-toggle::after {
    background: #fff;
    color: var(--color-one);
  }

  .theme-menu .navbar .navbar-nav .nav-link,
  .theme-menu .navbar .dropdown-menu {
    border-bottom: 1px solid #9563ff;
  }

  .theme-menu .navbar .dropdown-menu .dropdown-item {
    color: #fff;
  }

  .theme-menu .navbar .dropdown-menu {
    background: transparent;
  }

  .theme-menu .navbar .dropdown-submenu .dropdown-toggle::after {
    color: #fff;
  }

  .theme-menu .navbar .dropdown-menu .dropdown:hover>.dropdown-item,
  .theme-menu .navbar .dropdown-menu .dropdown-item.active,
  .theme-menu .navbar .dropdown-menu .dropdown-item:hover {
    background: #fff;
    color: var(--color-one);
  }

  .theme-menu .navbar .dropdown-submenu:hover>.dropdown-toggle::after {
    color: var(--color-one);
  }
}
