/* src/index.css */

/* Import Tailwind's base styles */
@tailwind base;

/* Import Tailwind's component styles */
@tailwind components;

/* Import Tailwind's utility classes */
@tailwind utilities;

/* Add any custom styles below, if needed */
