@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&amp;display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&amp;display=swap');

/*
* ----------------------------------------------------------------------------------------
* 01. Color
* ----------------------------------------------------------------------------------------
*/


:root {
  --font-theme: 'Ubuntu', sans-serif;
  --font-theme-two: 'Manrope', sans-serif;
  --color-one: #2a862e;
  --color-one-dark: #257629;
  --color-one-rgb: 42, 134, 46;
  --color-two: #ffd900;
  --color-two-dark: #f2ce05;
  --color-two-rgb: 255, 217, 0;
  --color-three: #1372de;
  --color-three-dark: #1168cb;
  --color-three-rgb: 19, 114, 222;
  --color-black: #000000;
  --color-black-rgb: 0, 0, 0;
  --color-white: #ffffff;
  --color-white-rgb: 255, 255, 255;
  --font-bootstrap: bootstrap-icons;
  --font-remixicon: remixicon;
  --transition: all 0.3s;
  --color-four: #f0f7fd;
}


/*scrollbar css start*/

* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-one) transparent;
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--color-one);
}
::selection {
  background: var(--color-one);
}
::selection {
  color: var(--color-white);
  text-shadow: none;
}
:root {
  scroll-behavior: smooth;
}
/*scrollbar css end*/


/*
* ----------------------------------------------------------------------------------------
* 02. Color Classes
* ----------------------------------------------------------------------------------------
*/

.color-one {
  color: var(--color-one) !important;
}

.color-two {
  color: var(--color-two) !important;
}

.color-three {
  color: var(--color-three) !important;
}

.color-black {
  color: var(--color-black) !important;
}

.color-white {
  color: var(--color-white) !important;
}

.bg-one {
  background-color: var(--color-one) !important;
}

.bg-two {
  background-color: var(--color-two) !important;
}

.bg-three {
  background-color: var(--color-three) !important;
}

.bg-black {
  background-color: var(--color-black) !important;
}

.bg-white {
  background-color: var(--color-white) !important;
}

.bg-four {
  background-color: var(--color-four) !important;
}

/*
* ----------------------------------------------------------------------------------------
* 04. Container Width
* ----------------------------------------------------------------------------------------
*/

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1300px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1170px;
  }
}

@media (min-width: 1350px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1220px;
  }
}


/*
* ----------------------------------------------------------------------------------------
* 04. Button Css
* ----------------------------------------------------------------------------------------
*/

.theme-btn,
.theme-btn:focus {
  transition: var(--transition);
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  width: fit-content;
  display: block;
  padding: 13px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-family: var(--font-theme-two);
}

.theme-btn i {
  margin-inline-start: 10px;
}

.theme-btn i.start {
  margin-inline-end: 10px;
  margin-inline-start: 0px;
}

.theme-btn:hover {
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
}

.theme-btn.btn-small{
  padding: 7px 15px;
  font-size: 14px;
}


.theme-btn.width-increase:hover {
  padding: 13px 25px;
}
.theme-btn.width-increase.btn-small:hover {
  padding: 7px 19px;
}


.theme-btn.one,
.theme-btn.one:focus {
  background: var(--color-one);
  color: var(--color-white);
}

.theme-btn.one:hover {
  background: var(--color-one-dark);
}

.theme-btn.two, .theme-btn.two:focus {
  background: var(--color-one);
  color: var(--color-white);
}
.theme-btn.two:hover {
  background: var(--color-one-dark);
}

.theme-btn.three,
.theme-btn.three:focus {
  background: var(--color-one);
  color: var(--color-white);
}

.theme-btn.three:hover {
  background: var(--color-one-dark);
}

.plan-two-card.active .theme-btn.two{
  background: var(--color-two);
  color: var(--color-black);
}

.plan-two-card.active .theme-btn.two:hover{
  background: var(--color-two-dark);
  color: var(--color-black);
}



/*
* ----------------------------------------------------------------------------------------
* 04. Section Gap
* ----------------------------------------------------------------------------------------
*/

.theme-section-gap {
  padding: 60px 0px;
}


.theme-section-heading h2 {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 10px;
}

@media (max-width:550px) {
  .theme-section-heading h2{
    font-size: 32px;
  }
}

.theme-section-heading p {
	font-size: 17px;
	font-family: var(--font-theme-two);
	color: #666866;
	line-height: 2;
}

@media (max-width:550px) {
  .theme-section-heading p{
    font-size: 15px;
  }
}

.theme-section-heading .style {
	color: var(--color-white);
	width: fit-content;
	border-radius: 4px;
	background: var(--color-one);
	line-height: 1.5;
	font-weight: 400;
	text-transform: uppercase;
	padding: 5px 10px;
	font-size: 13px;
	margin-bottom: 10px;
	letter-spacing: 0.8px;
	font-family: var(--font-theme-two);
}
.theme-section-heading.center,
.theme-section-heading.center h2,
.theme-section-heading.center p,
.theme-section-heading.center .style {
  text-align: center;
}

.theme-section-heading.center .style {
  margin: auto;
}

.theme-section-gap.bg-img-one{
  background: linear-gradient(45deg, rgba(61, 96, 63, 0.82) 0%, rgba(61, 96, 63, 0.82) 100%),  center center no-repeat;
    background-attachment: scroll, scroll;
    background-size: auto, auto;
  background-size: cover;
}




.highlight {
	font-weight: 700;
	overflow: hidden;
	position: relative;
	z-index: 1;
	/* display: inline-block; */
}

.highlight:after {
  content: '';
  background: var(--color-two);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
  left: 0;
  z-index: -1;
  transition: all 0.2s;
}

.highlight.thirty:after {
  height: 30%;
}

.highlight:hover:after {
  height: 100%;
}


/*
* ----------------------------------------------------------------------------------------
* Top Bar
* ----------------------------------------------------------------------------------------
*/

.topbar{
  position: absolute;
  width: 100%;
  top: 5px;
}

.topbar .top-bar-sale {
	font-size: 16px;
	font-weight: 500;
	font-family: var(--font-theme-two);
	color: #585954;
	letter-spacing: 0.3px;
}

.top-bar-dropdown button {
	font-size: 15px;
	font-weight: 500;
	font-family: var(--font-theme-two);
	color: #686868;
	letter-spacing: 0.5px;
	background: none;
	outline: none;
	border: none;
}



.top-bar-dropdown button:after{
  content: '\EA4E';
  font-family: var(--font-remixicon);
  color: #424242;
  font-size: 16px;
  transition: all 0.3s;
  display: inline-block;
}

.top-bar-dropdown button.show:after{
  transform: rotate(-180deg);
  color: var(--color-one);
}

.top-bar-dropdown button.show{
  color: var(--color-one);
}

.top-bar-dropdowns .top-bar-dropdown + .top-bar-dropdown{
  margin-inline-start: 5px;
}

.top-bar-dropdown.language-dropdown-menu .dropdown-menu{
max-height: 400px;
overflow: auto;
}

.top-bar-dropdown.nofication-dropdown-menu .dropdown-menu{
  min-width: 300px;
}

.topbar.white .top-bar-sale{
color: #fff;
}


.topbar.white .top-bar-dropdown button {
  color: #fff;
}

.topbar.white .top-bar-dropdown button:after{
  color: #fff;
}


.dropdown-menu{
  border: none;
  border-radius: 4px;
  padding: 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.dropdown-menu li + li{
  border-top: 1px solid #cac7c7;
}

.dropdown-menu li a {
  display: block;
	padding: 10px;
	font-size: 14px;
	font-weight: 600;
	font-family: var(--font-theme-two);
	color: #828282;
  transition: all 0.3s;
}
.dropdown-menu li a:hover{
  color: var(--color-one);
}

/*
* ----------------------------------------------------------------------------------------
* Header Style
* ----------------------------------------------------------------------------------------
*/

.theme-main-menu {
	position: absolute;
	width: 100%;
	top: 37px;
	z-index: 5;
	transition: height .5s ease-in;
}

@media (max-width:1200px) {
  .theme-main-menu >.rounded{
    padding: 10px;
  }
}
@media (max-width:550px) {
  .theme-main-menu{
    top: 35px;
  }
  .theme-main-menu >.rounded{
    border-radius: 0px !important;
    padding: 10px;
  }
}



.theme-main-menu.sticky{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: unset;
  background-color: var(--color-white);
  animation: headerSticky .95s ease forwards;
  box-shadow: var(--bs-box-shadow) !important;
  z-index: 99;
  max-width: 100%;
}

@keyframes headerSticky {
  0% {
      transform: translateY(-100%);
  }
  100% {
      transform: translateY(0);
  }
}

.theme-main-menu.sticky > .shadow{
  box-shadow: unset !important;
}



.navbar-toggler {
  width: 44px;
  height: 38px;
  padding: 0;
  box-shadow: none;
  position: relative;
  z-index: 99;
  border: none;
  background: var(--color-one);
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler::before,
.navbar-toggler::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 9px;
  width: 26px;
  height: 2px;
  pointer-events: none;
  -webkit-transition: -webkit-transform 0.25s;
  transition: transform 0.25s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background: #fff;
}

.navbar-toggler span {
  position: absolute;
  left: 9px;
  overflow: hidden;
  width: 26px;
  height: 2px;
  margin-top: -1px;
  text-indent: 200%;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  background: #fff;
}

.navbar-toggler::before {
  -webkit-transform: translate3d(0, -9px, 0) scale3d(1, 1, 1);
  transform: translate3d(0, -9px, 0) scale3d(1, 1, 1);
}

.navbar-toggler::after {
  -webkit-transform: translate3d(0, 8px, 0) scale3d(1, 1, 1);
  transform: translate3d(0, 8px, 0) scale3d(1, 1, 1);
}

.navbar-toggler[aria-expanded="true"] span {
  opacity: 0;
}

.navbar-toggler[aria-expanded="true"]::before {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
}

.navbar-toggler[aria-expanded="true"]::after {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
  transform: rotate3d(0, 0, 1, -45deg);
}

.theme-menu .navbar-toggler {
  background: #fff;
}

.theme-menu .navbar-toggler::before,
.theme-menu .navbar-toggler::after,
.theme-menu .navbar-toggler span {
  background: var(--color-one);
}

.navbar .navbar-nav .nav-link {
  font-family: var(--font-theme-two);
  font-size: 15px;
  font-weight: 500;
  line-height: initial;
  color: #000;
  padding: 15px 0;
  margin: 0 26px;
  position: relative;
}


.dropdown-icon {
  position: absolute;
  right: 0;
}

@media(min-width:1200px) {
  .navbar .navbar-nav .nav-link:after {
    content: " ";
    position: absolute;
    background: var(--color-one);
    top: 100%;
    margin-top: -7px;
    border-radius: 3px;
    left: 0%;
    width: 100%;
    height: 3px;
    transform: scaleX(0);
    transition: transform 0.2s;
    transform-origin: right center;
    box-sizing: border-box;
    display: block;
  }

  .navbar .navbar-nav .nav-item:hover .nav-link:after,
  .navbar .navbar-nav .nav-item.active .nav-link:hover:after {
    transform: scaleX(1);
    transform-origin: left center;
  }
}

.navbar .navbar-nav .nav-item.active .nav-link,
.navbar .navbar-nav .nav-item:hover .nav-link {
  color: var(--color-one);
}

.navbar .dropdown-menu .dropdown-item {
  font-family: var(--font-theme-two);
  font-size: 15px;
  padding: 0 15px;
  border-radius: 5px;
  letter-spacing: 0px;
  text-transform: capitalize;
  line-height: 40px;
  margin-bottom: 5px;
  color: #00284E;
  background: transparent;
  display: inline-block;
  position: relative;
  transition: all 0.3s ease-in-out;
  white-space: inherit;
  font-weight: 500;
}

.navbar .dropdown-menu .dropdown-item span {
  font-family: var(--font-theme-two);
}

.navbar .dropdown-menu .dropdown-item .tag {
  position: absolute;
  background: var(--red-one);
  line-height: 15px;
  color: #fff;
  font-size: 10px;
  letter-spacing: 1px;
  padding: 0 4px;
  text-transform: uppercase;
  top: 0;
  right: 0;
  font-style: normal;
}

.navbar .dropdown-menu .dropdown:hover>.dropdown-item,
.navbar .dropdown-menu .dropdown-item.active,
.navbar .dropdown-menu .dropdown-item:hover {
  background: var(--color-one);
  color: #fff;
}

.navbar .dropdown-toggle::after {
  content: "\f282";
  font-family: bootstrap-icons;
  border: none !important;
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  border-radius: 3px;
  background: var(--color-one);
  right: 0;
  top: calc(50% - 15px);
  text-align: center;
  transition: all 0.3s ease;
}

.navbar .show.dropdown-toggle::after {
  transform: rotate(180deg);
}

.navbar .dropdown-submenu .dropdown-toggle::after {
  content: "\f285";
  font-family: bootstrap-icons !important;
  display: block;
  right: 15px;
  top: 0;
  background: transparent !important;
  color: inherit;
  width: auto;
  height: auto;
  line-height: 40px;
  font-weight: normal;
  font-size: 13px;
  transition: transform 0.3s ease-in-out;
}

.navbar .dropdown-submenu:hover>.dropdown-toggle::after {
  transform: rotate(90deg);
}

.navbar .dropdown-menu li + li{
  border: unset;
}

/*----- For Desktop -------*/
@media screen and (min-width: 1200px) {
  .navbar .dropdown-toggle::after {
    display: none;
  }

  .navbar .dropdown-menu {
    z-index: 5;
    background-color: #fff;
    border-radius: 10px;
    display: block;
    right: 0;
    left: 0;
    padding: 10px;
    border: none;
    top: 100%;
    visibility: hidden;
    transform: scale(1, 0);
    opacity: 0;
    min-width: 250px;
    box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.12);
    margin: 0;
    transform-origin: 0 0;
    transition: all 0.3s ease-in-out;
  }

  .navbar .dropdown-menu .dropdown-menu {
    left: calc(100% + 10px);
    top: 0;
    right: auto;
    min-width: 240px;
    box-shadow: 0 20px 30px -10px rgb(0, 0, 0, 0.15);
    transform: scale(0, 1);
  }

  .navbar .dropdown:hover>.dropdown-menu {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }

}

.dropdown-item i {
  margin-inline-end: 5px;
}

.logo img {
  width: 80%;
}

.footer-logo img {
  width: 200px
}

/*(min-width: 992px)*/

/*----- For Mobile ----*/
@media screen and (max-width: 1200px) {
  .navbar {
    padding: 0;
  }

  .navbar-collapse .logo {
    margin-bottom: 0vh;
  }

  .navbar-collapse .logo img{
    max-height: 48px;
    width: auto;
  }

  .navbar-collapse {
    position: fixed;
    top: 0;
    background-color: #ffffff;
    left: 0;
    height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    clear: both;
    width: 320px;
    max-width: calc(100vw - 60px);
    z-index: 9999;
    transform: translateX(-100%);
    display: block !important;
    padding: 32px 15px 20px;
    transition: all 0.3s ease-in-out;
  }

  .navbar-collapse.show {
    transform: translateX(0);
    box-shadow: 15px 0 25px rgba(35, 35, 35, 0.1);
  }

  .theme-main-menu .navbar .mega-dropdown {
    position: relative;
  }

  .navbar .navbar-nav .nav-link {
    margin: 0;
    padding: 13px 0;
    border-bottom: 1px solid #f3f3f3;
  }

  .navbar .dropdown-menu .dropdown-item {
    padding: 0 10px;
    font-size: 16px;
  }

  .navbar .dropdown-menu {
    border: none;
    border-bottom: 1px solid #f3f3f3;
    padding: 0;
    border-radius: 0;
    margin: 0;
  }

  .navbar .dropdown-submenu .dropdown-menu {
    margin: 0;
    padding: 0 0 0 15px;
  }

  .theme-menu-four .navbar .navbar-nav .nav-link:before {
    display: none;
  }

  /*menu three*/
  .theme-menu .navbar-collapse {
    background: #6b28ff;
  }

  .theme-menu .navbar .dropdown-toggle::after {
    background: #fff;
    color: var(--color-one);
  }

  .theme-menu .navbar .navbar-nav .nav-link,
  .theme-menu .navbar .dropdown-menu {
    border-bottom: 1px solid #9563ff;
  }

  .theme-menu .navbar .dropdown-menu .dropdown-item {
    color: #fff;
  }

  .theme-menu .navbar .dropdown-menu {
    background: transparent;
  }

  .theme-menu .navbar .dropdown-submenu .dropdown-toggle::after {
    color: #fff;
  }

  .theme-menu .navbar .dropdown-menu .dropdown:hover>.dropdown-item,
  .theme-menu .navbar .dropdown-menu .dropdown-item.active,
  .theme-menu .navbar .dropdown-menu .dropdown-item:hover {
    background: #fff;
    color: var(--color-one);
  }

  .theme-menu .navbar .dropdown-submenu:hover>.dropdown-toggle::after {
    color: var(--color-one);
  }
}



/*
* ----------------------------------------------------------------------------------------
* 04. Banner Style
* ----------------------------------------------------------------------------------------
*/

.hero-banner {
  padding: 130px 0px 100px;
  z-index: -1;
}

.hero-banner.bg1 {
  background-color: transparent;
  background-image: linear-gradient(80deg, #2A862E5E 0%, #F0E28F75 100%);
}

.hero-banner.bg2 {
  background-color: rgba(101, 167, 242, 0.15);
}

.hero-banner.bg3 {
  background-image: linear-gradient(to right, #1372de, #30ba42);
  background-attachment: fixed;
  background-repeat: repeat-y;
}

.hero-banner.bg4{
  background: linear-gradient(to right, rgba(101, 167, 242, 0.15), rgba(117, 219, 130, 0.15));
}

.hero-banner.bg5{
  background-color: transparent;
  background-image: linear-gradient(80deg, #D8F1DC 0%, #D9E8DA6B 100%);
}


.hero-banner.bg6{
  background-color: rgba(101, 167, 242, 0.15);
}

.hero-banner.bg7{
  background-color: rgba(101, 167, 242, 0.15);
}


.hero-banner .inner-content .style {
  color: var(--color-white);
  width: fit-content;
  border-radius: 4px;
  background: var(--color-one);
  line-height: 1.5;
  font-weight: 400;
  text-transform: uppercase;
  padding: 5px 10px;
  font-size: 14px;
  margin-bottom: 10px;
}

.hero-banner .inner-content h1 {
  font-size: 50px;
  margin-bottom: 15px;
}

@media (max-width:767px) {
  .hero-banner .inner-content h1 {
    font-size: 35px;
  }
}

.hero-banner .inner-content p {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.5;
  font-family: var(--font-theme-two);

}

.hero-banner .inner-content p.big {
  font-size: 20px;
  font-weight: 600;
  font-family: var(--font-theme);
  margin-bottom: 15px;
}


.hero-banner .inner-content .banner-list li {
  padding-inline-start: 19px;
  position: relative;
  font-size: 16px;
  font-family: var(--font-theme-two);
}

.hero-banner .inner-content .banner-list li+li {
  margin-top: 5px;
}

.hero-banner .inner-content .banner-list li:after {
  content: '\F270';
  font-family: var(--font-bootstrap);
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-one);
}

.herobannerimg-box {
  background-color: var(--color-white);
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}




.hero-banner-bottom-slider {
	background: #fbf4f4;
	padding: 7px;
}



.image-slider {
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slider-track {
	display: flex;
	width: calc(250px * 18);
	animation: scroll 40s linear infinite;
	align-items: center;
}

.slider-track:hover {
  animation-play-state: paused;
}

.slides {
  width: 200px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}

.slides span {
  margin-top: 20px;
  font-family: b Biger Over;
}


.slides img {
  width: 60%;
  transition: all .8s;
}

.slides img:hover {
  transform: translateZ(+20px);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 9));
  }
}


/*
* ----------------------------------------------------------------------------------------
* 04. Services  Styles
* ----------------------------------------------------------------------------------------
*/
.style-one-card {
	display: flex;
	padding: 20px;
	border: 2px dashed #c4c4c4;
	background-color: #EFF2F7;
	height: 100%;
	transition: all .5s;
  position: relative;
  top: 0;
}

.style-one-card:hover {
  top: -10px;
  padding: 20px 30px;
  /* box-shadow: 0px 0px 15px rgba(0,0,0,0.07); */
}

.style-one-card.one{
  background: #fffccf;
  border: 2px dashed #dbd8ad;
}

.style-one-card.two{
  background: #ffedff;
  border: 2px dashed #e6d3e6;
}

.style-one-card.three{
  background: #ffe8eb;
  border: 2px dashed #d9c3c6;
}

.style-one-card.four{
  background: #e9f6ff;
  border: 2px dashed #d9c3c6;
}

.style-one-card.five {
	background: #f2ffe9;
	border: 2px dashed #d4e1ca;
}

.style-one-card.six{
  background: rgb(254, 233, 255);
  border: 2px dashed #d9c3d6;
}



.style-one-card h3 {
  margin-bottom: 15px;
}

.style-one-card h3,
.style-one-card p {
  color: var(--color-black);
}

.style-one-card svg {
	width: 46px;
	height: 46px;
	color: #20262073;
	margin-right: 15px;
}

@media(max-width:480px) {
  .style-one-card {
    display: block;
  }

  .style-one-card svg {
    display: block;
    font-size: 46px;
  }

  .style-one-card h3 {
    display: block;
    margin-top: 20px;
  }
}


/*.service-style-two-card{
  background-color: #F6F9FF;
}*/
.service-style-two-card {
  padding: 20px;
  height: 100%;
  transition: all .5s;
  height: 100%;
  border-radius: 10px;
}

.service-style-two-card:hover {
  transform: translate(5px, 5px);
}

.service-style-two-card .icon {
	width: fit-content;
	padding: 17px;
	background: #fff;
	border-radius: 10px;
	border-radius: 50%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.service-style-two-card .icon {
  margin-bottom: 20px;
}

.service-style-two-card .card-content h3 {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 15px;
}

.service-style-two-card .icon svg {
	width: 35px;
	height: 35px;
	fill: var(--color-one);
}

.service-style-two-card .icon svg path,
.service-style-two-card svg circle,
.service-style-two-card svg rect {
  fill: var(--color-one);
}



.popular-domains {
	border-radius: 10px;
	padding: 20px;
	border: 2px dashed #becabe;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
	transition: all .5s;
	/* height: 100%; */
}
.popular-domains:hover {
	border-style: solid;
  border-color: var(--color-one);
}

.popular-domains h4 {
	font-size: 45px;
	font-weight: 800;
	text-align: center;
	margin-bottom: 0;
	line-height: 1;
	text-transform: capitalize;
	text-overflow: ellipsis;
	overflow: hidden;
	font-family: var(--font-theme-two);
	color: #535252;
	margin-bottom: 10px;
}

.popular-domains .content > span{
  color: #4a5568;
  font-family: var(--font-theme-two);
  font-weight: 500;
  font-size: 15px;
}

.popular-domains .content h2{
  font-size: 25px;
  font-family: var(--font-theme-two);
  margin-top: 10px;
}

.popular-domains .content h2 sup{
  font-family: var(--font-theme-two); 
}

.popular-domains .content h2 .highlight{
  font-family: var(--font-theme-two); 
}

.popular-domains .theme-btn {
  margin: auto;
  margin-top: 20px;
}

.comingdomains {
  background-color: transparent;
  background-image: linear-gradient(to right, #1372de, #30ba42) !important;
}

.domain-search-box input {
  padding: 10px;
  width: 65%;
  line-height: 25px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-color: var(--color-white);
  border: unset;
}


.service-style-three-card {
  border: 0px solid var(--color-black);
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
  height: 100%;
  position: relative;
  background: #f6f9ff;
}

.service-style-three-card .icon {
  margin-bottom: 20px;
}

.service-style-three-card .card-content h3 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 15px;
}


.service-style-three-card .icon svg {
	width: 41px;
	height: 41px;
	fill: var(--color-one);
}

.service-style-three-card i {
	position: absolute;
	right: 10%;
	font-size: 40px;
	color: var(--color-one);
	opacity: .2;
	top: 3%;
}

@media(min-width:992px) {
  .shopping-cart {
    padding: 100px 0px;
  }
}

.shopping-cart img {
  width: 70px;
  padding: 10px;
  background: var(--color-white);
  border-radius: 15%;
}

.shopping-cart .image1 {
  position: relative;
  top: -41px;
}

.shopping-cart .image2 {
  position: relative;
  top: 14px;
  left: 33px;
}

.shopping-cart .image3 {
  position: relative;
  top: -28px;
  right: -75px;
}

.shopping-cart .image4 {
  position: relative;
  top: 84px;
  left: 0px;
}

.shopping-cart .image5 {
  position: relative;
  top: 11px;
  right: -124px;
}



@media(max-width:992px) {

  .shopping-cart .image1,
  .shopping-cart .image2,
  .shopping-cart .image3,
  .shopping-cart .image4,
  .shopping-cart .image5 {
    position: unset;
    top: 0;
    left: 0;
    right: 0;
  }

  .shopping-cart .shopping-cart-heading {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
  }

  .shopping-cart img {
    width: 45px;
    padding: 6px;
    background: var(--color-white);
    border-radius: 50%;
  }

  .shopping-cart {
    padding: 20px;
  }

  .shopping-cart h2 {
    font-size: 20px;
  }
}


.privacy-policy-section {
  padding: 120px 0px;
}


.privacy-policy-section h1 {
  font-size: 50px;
}

.privacy-policy-list {
  display: flex;
  text-align: center;
  margin: auto;
  justify-content: center;
  margin-top: 20px;
}

.privacy-policy-list li {
  margin-inline-end: 15px;
}

.privacy-policy-list li i {
  color: rgba(255, 255, 255, 0.7) !important;
}

.privacy-policy-list li a {
  font-size: 17px;
  color: rgba(255, 255, 255, 0.7) !important;
  font-weight: 600;
}

@media(max-width:480px) {
  .privacy-policy-section h1 {
    font-size: 40px;
  }

  .privacy-policy-list {
    display: block;
  }

  .privacy-policy-section {
    padding: 60px 0px;
  }
}

.termscondition-section .banner-list li {
  width: 100%;
}

.border-style {
  border: 1px solid var(--color-one);
}

.customer-support-box {
  padding: 50px;
  border-radius: 10px;
  background-color: #F6F9FF;
}

.customer-support-text {
  display: flex;
  padding: 7px;
  align-items: center;
  background: var(--color-white);
  margin-bottom: 20px;
  border-radius: 10px;
  justify-content: space-around;
}

.customer-support-text span {
  display: block;
}

.customer-support-text .lable {
	font-size: 16px;
	font-weight: 500;
	font-family: var(--font-theme-two);
	text-transform: capitalize;
}

.cus-email {
  text-align: start;
  margin-inline-start: 13px;
  line-height: 20px;
  width: 66%;
}

.customer-support-text a {
	color: var(--color-one);
	font-weight: 500;
	font-family: var(--font-theme-two);
	font-size: 14px;
}

.customer-support-text .icon {
  font-size: 30px;
  color: var(--color-one);
}

.customer-support-text .icon-2 i {
  margin-inline-start: 13px;
  font-size: 25px;
  color: var(--color-two);
}

@media(max-width:480px) {
  .customer-support-text {
    margin-bottom: 20px;
  }

  .customer-support-box {
    padding: 20px;
    border-radius: 10px;
  }

}


.tabs-style-one .tabs {
  display: flex;
  flex-direction: column;
}

.tabs-style-one .tabs button {
  width: 100%;
}



.tabs-style-one .tabs button {
  background: #383B4B;
  border: 2px solid #383B4B;
  padding: 10px;
  margin-bottom: 20px;
  color: var(--color-white);
  font-weight: 500;
  border-radius: 10px;
}

.tabs-style-one .tabs button.active {
  background-color: var(--color-one);
  color: var(--color-white);
  border-color: var(--color-one);
}

.tabs-style-one .tabscontents li {
	align-items: start;
	display: flex;
	font-weight: 400;
	font-size: 15px;
	line-height: 35px;
	font-family: var(--font-theme-two);
	color: #7d7d7d;
}

.tabs-style-one .tabscontents li:before {
  content: "\F270";
  font-family: bootstrap-icons;
  font-size: 20px;
  margin-inline-end: 10px;
  color: var(--color-two);
}

.tabs-style-one .tabs-button {
  display: flex;
  flex-direction: row;
}

.tabs-style-one .tabs-button button {
  width: unset;
  margin-inline-end: 30px;
}

@media(max-width:480px) {
  .tabs-style-one .tabs-button {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .tabs-style-one .tabs-button button {
    width: 100%;
    margin-inline-end: 0px;
  }

}


.domain-search-box {
  justify-content: center;
  display: flex;
}

.domain-search-box input {
  padding: 15px;
  width: 65%;
  line-height: 25px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-color: var(--color-white);
  border: unset;
}

.domain-search-box input:focus {
  outline: none;
}


.domain-search-box button {
  color: var(--color-white);
  font-weight: 500;
  width: 15%;
  padding: 10px;
  border: 0px solid var(--color-one);
  background-color: var(--color-one);
  line-height: 25px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.domain-register-box-bottom-img {
  margin-top: 40px;
  justify-content: space-between;
  display: flex;
}



@media(max-width:480px) {
  .domain-search-box {
    display: flex;
    flex-direction: column;
  }

  .domain-search-box input {
    width: 100%;
    border-radius: 5px;
  }

  .domain-search-box button {
    width: 50%;
    border-radius: 5px;
    display: block;
    margin-top: 20px;
  }

}

.domain-register-box-bottom-img span {
	font-family: var(--font-theme-two);
}

.domain-register-box-bottom-img .domain-name {
  font-size: 25px;
  font-weight: 700;
  font-family: var(--font-theme-two);
}

.domain-register-box-bottom-img .price {
	font-size: 18px;
	font-weight: 700;
	font-family: var(--font-theme-two);
	color: #757474;
	letter-spacing: 0.5px;
}


@media(max-width:567px) {
  .domain-register-box {
    padding: 120px 0px 40px 0px;
  }

  .domain-register-box .having-domain {
    margin-top: 20px;
  }

  .domain-register-box-bottom-img {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

  }

  .domain-register-box-bottom-img img {
    width: 70px;
    margin-inline-end: 20px;
    margin-top: 20px;
  }
}


@media(max-width:767px) {
  .domain-register-box-bottom-img {
    margin-top: 40px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }

  .domain-register-box-card {
    width: 25%;
    margin-bottom: 20px;
  }
}

@media(max-width:480px) {
  .domain-register-box-card {
    width: 50%;
    margin-bottom: 20px;
    text-align: center;
  }
}

.domain-transfer-box {
  background-image: linear-gradient(to right, #1372de, #30ba42) !important;
  background-attachment: fixed !important;
  background-repeat: repeat-y;
}

.domain-transfer-box {
  padding: 120px 0px 60px 0px;
}

.domaintrans-form input {
  padding: 12px;
  border-radius: 10px;
  border: unset;
  margin-bottom: 10px;
}

.domaintrans-form select {
  padding: 12px;
  border-radius: 10px;
  border: unset;
  margin-bottom: 10px;
}

.faq-list-tick li {
  display: flex;
  align-items: start;
  line-height: 30px;
}

.faq-list-tick li:before {
  content: "\F133";
  font-family: bootstrap-icons;
  font-size: 20px;
  color: var(--color-one);
  margin-inline-end: 10px;
}


.domain-promo-page {
  background: linear-gradient(to right, rgba(101, 167, 242, 0.15), rgba(117, 219, 130, 0.15)) !important;
}

.promos-view-banner {
  background: #f3f3f3;
}

.domain-promo-page {
  padding: 90px 0px 60px 0px;
}

.domain-promo-page h1 {
  font-size: 70px;
}


.promos-view-card {
  background-color: #f3f6f9;
  border-radius: 5px;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  margin-bottom: 30px;
  padding: 20px;
  text-align: center;
  transition: all 0.5s;
}

.style-three-list-tick li {
  display: flex;
  align-items: start;
  font-family: var(--font-theme);
  line-height: 37px;
}

.style-three-list-tick li:before {
  content: "\F133";
  font-family: bootstrap-icons;
  font-size: 20px;
  color: var(--color-one);
  margin-inline-end: 10px;
}

.linux-banner-page {
  padding: 120px 0px 60px 0px;
  background-color: transparent;
  background-image: linear-gradient(80deg, #D8F1DC 0%, #D9E8DA6B 100%);
}

.note1 {
  padding: 10px;
  background: var(--color-one);
  color: var(--color-white);
  border-radius: 10px;
  display: block;
  width: 50%;
}

.linux-banner-page img {
  width: 90%;
  padding: 10px;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.wordpress-page {
  background: linear-gradient(to right, rgba(101, 167, 242, 0.15), rgba(117, 219, 130, 0.15)) !important;
}

.WordPress-listing {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.WordPress-listing li {
  width: fit-content;
  display: flex;
  align-items: center;
}

.WordPress-listing li i {
  font-size: 25px;
  color: #2a862e;
  margin-inline-end: 10px;
}
@media(max-width:567px) {
  .WordPress-listing {
    display: block;
  }

  .WordPress-listing li {
    width: 100%;
    align-items: center;
  }
}

@media(max-width:480px) {
  .note1 {
    width: 100%;
  }

  .linux-banner-page img {
    width: 100%;
  }

  .herobannerimg-box {
    padding: 0px;
  }
}

@media(max-width:992px) {
  .note1 {
    width: 100%;
  }
}

.linux-vps-page {
  padding: 120px 0px 60px 0px;
  background-image: linear-gradient(to right, #1372de78, #30ba428c) !important;
  background-attachment: fixed !important;
  background-repeat: repeat-y;
}

.linux-vps-page img {
  width: 90%;
  background: var(--color-white);
  padding: 20px;
  border-radius: 20px;
}

@media(max-width:480px) {
  .linux-vps-page img {
    width: 100%;
  }
}


.plan-banner {
  background-color: var(--color-white);
  border-radius: 15px;
  padding: 0px 0px 8px 0px;
}


.plan-banner .highlighteed {
  background: var(--color-one-dark);
  width: 100%;
  padding: 20px;
  color: var(--color-white);
  display: block;
  border-radius: 10px 10px 0px 0px;
  font-weight: 500;
  font-size: 20px;
}
.plan-banner ul {
  padding: 0px 35px 0px 35px;
  margin-top: 10px;
}

.plan-banner li {
  display: flex;
  align-items: center;
  justify-content: start;
  border-bottom: 1px solid #dcdfde;
  text-align: start;
  line-height: 47px;
}

.plan-banner li i {
  font-size: 25px;
  color: var(--color-one);
  margin-inline-end: 10px;
}

.plan-banner .offer-price {
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  font-size: 30px;
}

.banner-style-two {
  padding: 0;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: fixed;
  position: relative;
}

.dedicated-plan-head {
  background-color: var(--color-one-dark);
  display: flex;
  padding: 8px;
  border-radius: 25px;
  color: var(--color-white);
  font-weight: 500;
}

.dedicated-plan-block {
  border: 2px dotted var(--color-one);
  padding: 20px;
  border-radius: 10px;
  transition: all .5s;
}

.dedicated-plan-block:hover {
  border: 2px solid var(--color-one);
}

.dedicated-plan-text .price-title {
  font-size: 25px;
  font-weight: 500;
}

.dedicated-plan-text .theme-btn {
  width: fit-content;
  text-align: center;
}

.dedicated-plan-text .heading-title::before {
  content: attr(data-list);
  position: relative;
  display: block;
  border-bottom: 1px solid #f8f8f8;
  font-size: 17px;
  line-height: 2;
  text-align: center;
  color: #515555;
}

@media (min-width:993px) {
  .dedicated-plan-text .heading-title::before {
    display: none;
  }
}

@media (max-width:992px) {
  .dedicated-plan-block {
    padding: 20px;
  }

  .dedicated-plan-text .heading-title::before {
    display: block;
  }

  .dedicated-plan-block .heading-title {
    font-size: 20px;
    margin: auto;
    display: block;
    text-align: center;
    line-height: 1.5;
  }

  .dedicated-plan-block .heading-title::before {
    content: attr(data-list);
    position: relative;
    display: block;
    border-bottom: 1px solid #f8f8f8;
    font-size: 17px;
    line-height: 2;
    text-align: center;
    color: #515555;
  }

  .dedicated-plan-block .heading-title {
    font-size: 16px;
    line-height: 3;
    text-align: center;
    display: block;
    border-bottom: 1px solid #f8f8f8;
  }

  .dedicated-plan-block .theme-btn {
    margin: auto !important;
    margin-top: 20px !important;
    display: block !important;
    width: 90%;
  }

  

}


.reseller-card-style li {
  display: flex;
  align-items: start;
}

.reseller-card-style li:before {
  content: "\F133";
  font-family: bootstrap-icons;
  font-size: 20px;
  color: var(--color-one);
  margin-inline-end: 10px;

}

.bussiness-page {
  padding: 120px 0px 60px 0px;
}

.bussiness-page img {
  border-radius: 20px;
  transition: all .5s;
}

.bussiness-page img:hover {
  transform: scale(1.1);
}


.enterprises-page {
  padding: 120px 0px 60px 0px;
  background: linear-gradient(to right, rgba(101, 167, 242, 0.15), rgba(117, 219, 130, 0.15)) !important;
}



.google-workspace-card {
  display: flex;
  padding: 20px;
  border: 2px dashed #fff;
  height: 100%;
  transition: all .5s;
  border-radius: 10px;
}

.google-workspace-card h3 {
  margin-bottom: 15px;
  color: var(--color-black);
}

.google-workspace-card h3,
.google-workspace-card p {
  color: var(--color-black);
}

.google-workspace-card svg {
  width: 46px;
  height: 46px;
  color: var(--color-one);
  margin-right: 15px;
}

@media(max-width:480px) {
  .google-workspace-card {
    display: block;
  }

  .google-workspace-card svg {
    display: block;
    font-size: 46px;
  }

  .google-workspace-card h3 {
    display: block;
    margin-top: 20px;
  }
}


.list-tick li {
	display: block;
	padding-inline-start: 20px;
	line-height: 1.5;
	position: relative;
	color: #575957;
	font-family: var(--font-theme-two);
	font-weight: 500;
}

.list-tick li::before {
	content: '\F270';
	font-size: 16px;
	font-family: var(--font-bootstrap);
	position: absolute;
	top: 0;
	left: 0px;
	color: var(--color-one);
	font-weight: 500;
}


.list-tick li +  li{
  margin-top: 10px;
}




.sitelock-card-style {
  background-color: var(--color-white);
  padding: 30px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 100%;
}

.sitelock-card-style .icon {
  width: fit-content;
  padding: 20px;
  background: aliceblue;
  border-radius: 50%;
  margin-bottom: 20px;
}

.sitelock-card-style .icon svg {
  fill: var(--color-one);
  width: 31px;
  height: 31px;
}



.sitelock-card-style .icon2 i {
  font-size: 30px;
}

@media(max-width:480px) {
  .sitelock-card-style {
    padding: 10px 20px 10px 20px;
    text-align: center;
  }

  .sitelock-card-style .icon {
    width: fit-content;
    margin: 20px auto;
  }
}

.sitelock-card-style h3{
  font-size: 25px;
}

.plan-one-card .select select {
  width: 100%;
  padding: 10px;
  border: unset;
  background: var(--color-one);
  border-radius: 4px;
  color: aliceblue;
}
.positiion-relative {
  position: relative;
}


.combo-offer-block {
	padding: 20px;
	background: #ffebe5;
	border-radius: 10px;
}



/* --------------------------------------------------------------------------

                              swiper slider

---------------------------------------------------------------------------*/


.swiper-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

[dir="rtl"] .swiper .swiper-wrapper {
  direction: ltr;
}


.swiper .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.swiper .swiper-pagination .swiper-pagination-bullet {
  width: 13px;
  height: 13px;
  background-color: var(--color-white);
  opacity: 1;
  border: 1px solid var(--color-one);
}


.swiper .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--color-one);
}

/*================================plan-one===================== */


.plan-one-card {
  border: 2px dotted var(--color-one);
  transition: var(--transition);
  border-radius: 10px;
  background: #f8fcff;
  padding: 20px;
  /* height: 100%; */
  position: relative;
}

.plan-top-head {
  width: 100%;
  border-radius: 10px;
  text-align: center;
  background: var(--color-one);
  padding: 7px;
  position: absolute;
  left: 0;
  top: -45px;
  color: var(--color-white);
}

.plan-one-card.active .theme-btn.two {
  background-color: var(--color-one);
  color: var(--color-white);
}

.plan-one-card .saving {
  background-color: var(--color-one-rgb);
}

.plan-one-card .saving h4 {
  padding: 5px;
  background-color: var(--color-one);
  color: var(--color-white);
  border-radius: 5px;
  font-size: 15px;
  background: linear-gradient(95.45deg, #A0C6A2 0.64%, #117116 98.23%);
}

.plan-one-card:hover {
  border: 2px solid var(--color-one);
}

.plan-one-card h3 {
	font-size: 23px;
	font-weight: 600;
	font-family: var(--font-theme-two);
	color: #515050;
}

.plan-one-card .price {
  font-size: 35px;
  font-weight: 600;
  margin-top: 15px;
  display: block;
}


.plan-listing ul {
  margin: 0px 0px 0px 0px;
}

.plan-listing ul li {
	line-height: 2;
	display: block;
	font-size: 16px;
	text-align: start;
	position: relative;
	padding-inline-start: 25px;
	font-family: var(--font-theme-two);
	color: #737373;
}

.plan-listing ul li::before {
	content: "\F26A";
	color: var(--color-one);
	font-family: bootstrap-icons;
	font-size: 14px;
	position: absolute;
	top: 3px;
	left: 5px;
}
.plan-listing ul li span {
  color: var(--color-one);
  font-weight: 500;
}

.plan-one-card .theme-btn {
  width: 100%;
  border-radius: 5px;
}

.plan-one-card .theme-btn {
	padding: 10px 20px;
	font-size: 15px;
	transition: var(--transition);
}

.plan-one-card .theme-btn:hover {
  padding: 10px 20px;
}

.plan-button {
  margin-top: 20px;
}


/*===============plan-two==============*/

.plan-two-card {
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  background: #fff;
}

.plan-two-card.active {
  background: var(--color-one);
  color: var(--color-white);
}

.plan-two-card.active li {
  color: var(--color-white) !important;
}

.plan-two-card.active li span {
  color: var(--color-black) !important;
}

.plan-two-card.active li:before {
  color: var(--color-white) !important;
}

.plan-two-card h3 {
  font-size: 30px;
  font-weight: 600;
}

.plan-two-card .price {
  font-size: 35px;
  font-weight: 600;
  margin-top: 15px;
  display: block;
}


.plan-two-card .plan-listing ul {
  margin: 0px 0px 0px 0px;
}



.plan-two-card .plan-listing ul li span {
  color: var(--color-one);
  font-weight: 500;
}

.plan-two-card .theme-btn {
  width: 100%;
  border-radius: 5px;
}

.plan-two-card .theme-btn {
  padding: 10px 20px;
  font-size: 18px;
  transition: var(--transition);
}

.plan-two-card .theme-btn:hover {
  padding: 10px 20px;
}

.select select {
  width: 100%;
  padding: 10px;
  border: unset;
  background: var(--color-one);
  border-radius: 4px;
  color: aliceblue;
}

.select.white-bg select {
  width: 100%;
  padding: 10px;
  border: unset;
  background: var(--color-white);
  border-radius: 4px;
  color: #000;
}

/*================================bussiness-plan===================== */



.bussiness-plan label {
  font-weight: var(--font-theme);
  font-weight: 500;
}

.bussiness-plan img {
  width: 80%;
}

.bussiness-plan .noaccounts input {
  background-color: var(--color-one);
  color: var(--color-white);
}

.bussiness-plan .duration select {
  background-color: var(--color-one);
  color: var(--color-white);
}

.bussiness-plan .noaccounts .form-control,
.bussiness-plan .duration .form-control:focus {
  outline: none;
  box-shadow: none;
  border: none;
}


/*=====================Login & Register==========================*/
.login-page {
  padding: 120px 0px 60px 0px;
}


.loginimg {
  width: 50%;
  background: #bbe1bd;
}

.loginimg:after {
  content: "/";
  position: relative;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(95.45deg, #A0C6A2 0.64%, #ffd34d 98.23%);
}

.login-text {
  width: 50%;
  padding: 50px;
}

.login-page .login-links a {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-inline-end: 50px;
}

.login-page .login-links a {
  position: relative;
  margin-bottom: 10px;
}

.login-page .login-links a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -6px;
  width: 70px;
  height: 3px;
  background: linear-gradient(95.45deg, #A0C6A2 0.64%, #ffd34d 98.23%);
}

.login-page form input {
  width: 60%;
  padding: 12px;
  border-radius: 8px;
  background-color: #fff;
  color: var(--color-white);
  margin-bottom: 20px;
  border: unset;
}

.login-page form input::placeholder {
  color: var(--color-two);
  opacity: 1;
}

.login-page .form-button {
  display: flex;
}

.login-page .form-button button {
  margin-inline-end: 50px;
}

.login-page .form-button .theme-btn i {
  margin-inline-end: 7px;
  margin-inline-start: 0px;
  font-weight: 500;
}

@media(max-width:992px) {
  .loginimg {
    width: 100%;
  }

  .login-text {
    width: 100%;
  }

  .login-page form input {
    width: 80%;
  }
}

@media(max-width:480px) {
  .login-page form input {
    width: 100%;
  }

  .login-text {
    padding: 25px;
  }

  .login-page .form-button {
    display: block;
  }

  .login-page .form-button .theme-btn {
    width: 100%;
    margin-bottom: 20px;
  }

}

/*=========== Register============*/

.register-login .register-text {
  width: 40%;
}

.register-text {
  justify-content: center;
  display: flex;
  align-items: center;
}

.register-form {
  width: 60%;
  padding: 50px;
}

.register-form input {
  margin-bottom: 20px;
  padding: 12px;
}

.register-form .acception input {
  margin-inline-end: 10px;
}

input[type="checkbox"] {
  accent-color: var(--color-one) !important;
  height: 16px;
  width: 16px;
}

@media(max-width:784px) {
  .register-login .register-text {
    width: 100%;
  }

  .register-form {
    width: 100%;
  }
}


@media(max-width:480px) {
  .register-form {
    padding: 25px;
  }

  .register-form .form-button {
    margin-top: 20px;
  }
}


.comboofferimg-box {
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}



/*=====================Login page==========================*/

.login-page {
  padding: 120px 0px 60px 0px;
}


.forget-pass-page {
  padding: 120px 0px 60px 0px;
  background: #bbe1bd;
}

.forget-pass-page input {
  padding: 15px;
}

.forget-pass-page form .theme-btn {
  width: 100%;
}

.forget-pass-page span {
  font-size: 25px;
}

/*=====================Register page==========================*/
.register-login {
  padding: 120px 0px 60px 0px;
}



/*=====================faq==========================*/




.faq-qa {
  padding: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 24px;
}


.accordion-item:not(:first-of-type) {
  border-top: 2px;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: none;
}

.accordion-item {
  border-bottom: 1px solid #e1e1e1 !important;
}

.accordion-button {
  background-color: var(--color-white);
}

.accordion-button:not(.collapsed) {
  color: var(--color-black);
  background-color: var(--color-white);
  box-shadow: unset;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--color-one);
  outline: 0;
  box-shadow: var(--color-one);
}

.accordion-header .accordion-button {
	color: #403f3f;
	font-weight: 500;
	line-height: 30px;
	font-size: 18px;
	font-family: var(--font-theme-two);
}

@media (max-width:767px){
  .accordion-header .accordion-button {
    color: #403f3f;
    font-weight: 500;
    line-height: 25px;
    font-size: 16px;
    font-family: var(--font-theme-two);
  }
}

.accordion-header .accordion-button:after {
  color: var(--color-white);
}

.faq .accordion-body a {
  color: var(--color-one);
  font-weight: 600;
}

.accordion-body {
	/* padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); */
	padding: 0px 0px 20px 20px;
	font-family: var(--font-theme-two);
	font-size: 15px;
	letter-spacing: 0.5px;
	color: #7d7a7a;
}


@media(max-width:480px) {
  .faq-qa {
    padding: 10px;
    border-radius: 10px;
  }

  .plan-top-head-card {
    margin-top: 30px;
  }
}

.featues-four {
	position: relative;
	z-index: 10;
	background: rgb(42, 134, 46);
	background-image: none;
	border-radius: 10px;
	backdrop-filter: blur(15px);
	background-image: linear-gradient(218.15deg, #b966e763 0%, #2f57ef4a 100%);
}

.featues-four .cards{
  padding: 40px 30px;
  z-index: 2;
  text-align: left;
  padding: 50px 50px;
  position: relative;
  border-inline-end: 1px solid #ffffff1a;
  height: 100%;
  transition: 0.3s;
}

.featues-four .featues-four-items .col-12:last-child .cards{
  border-inline-end: 0px;
}

@media (max-width:992px){
  .featues-four .featues-four-items .col-12:nth-child(2) .cards{
    border-inline-end: 0px;
  }
}

@media (max-width:767px){
  .featues-four .featues-four-items .col-12 .cards{
    border-inline-end: 0px;
  }
}

.featues-four .cards:hover{
  transform: translateY(-20px) scale(1.03);
  border-color: transparent;
}

.featues-four .cards::before {
	content: "";
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: var(--color-black);
	z-index: -1;
	position: absolute;
	border-radius: 10px;
	opacity: 0;
	transition: 0.3s;
}


.featues-four .cards:hover::before {
	opacity: 1;
	transform: scale(1);
}

.featues-four .cards .icon{
  margin-bottom: 20px;
}

.featues-four .cards .icon img{
  max-height: 60px;
}

.featues-four .cards .heading {
	color: #ffffff;
	font-size: 18px;
	font-weight: 600;
	font-family: var(--font-theme-two);
	margin-bottom: 20px;
}

.featues-four .cards .content{
  color: #ffffffb3;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  font-family: var(--font-theme-two);
}

footer {
  background-color: #f8f8f8;
  position: relative;
  z-index: 0;
  padding-top: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
}

footer::after {
  content: "";
  position: absolute;
  width: 30%;
  height: 100%;
  top: 0;
  left: 0;
  
  background-repeat: no-repeat;
  z-index: -1;
}


@media (max-width: 1199px) {
  footer::after {
    content: none
  }
}


@media (max-width: 1199px) {
  footer:before {
    content: none
  }
}

footer .footer-services .service-contain {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
}

footer .footer-services .service-contain .service-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

@media (max-width: 1200px) {
  footer .footer-services .service-contain .service-box {
    width: 50%
  }
}

@media (max-width: 1200px) {
  footer .footer-services .service-contain .service-box:nth-child(-n+2) {
    margin-bottom: 15px
  }
}

@media (max-width: 1200px) {
  footer .footer-services .service-contain .service-box:nth-child(3)::before {
    display: none
  }
}

@media (max-width: 767px) {
  footer .footer-services .service-contain .service-box::before {
    display: none
  }
}

footer .footer-services .service-contain .service-box+.service-box::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 50%;
  left: -104px;
  border: 1px dashed #c1c1c1
}
@media (max-width: 1660px) {
  footer .footer-services .service-contain .service-box + .service-box::before {
    left: -70px;
  }
}
@media (max-width: 1460px) {
  footer .footer-services .service-contain .service-box + .service-box::before {
    left: -36px;
  }
}

@media (max-width: 1366px) {
  footer .footer-services .service-contain .service-box+.service-box::before {
      left: -31px
  }

  [dir="rtl"] footer .footer-services .service-contain .service-box+.service-box::before {
      left: unset;
      right: -31px
  }
}




footer .footer-services .service-contain .service-box .content{
  margin-inline-start: 15px;
}


footer .footer-services .service-contain .service-box .content h5{
  font-weight: 500;
  color: #4a5568;
  font-size: 17px;
  font-family: var(--font-theme-two);
}

@media (max-width:767px){
  footer .footer-services{
    display: none;
  }
}

@media (min-width:767px) {
  footer .main-footer{
    border-top: 1px dashed #c1c1c1;
    padding-top: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
  }
}



footer .footer-logo a{
display: block;
}

footer .footer-logo img{
  width: calc(150px + (160 - 120) * ((100vw - 320px) / (1920 - 320)));
}

footer .footer-logo p {
	margin: 10px 0;
	font-size: 15px;
	color: #4a5568;
	line-height: 1.5;
	font-weight: 450;
  font-family: var(--font-theme-two);
}

footer .footer-logo .address{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
  margin-top: calc(7px + (30 - 7) * ((100vw - 320px) / (1920 - 320)));
}

footer .footer-logo .address li{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  font-size: 14px;
  color: #4a5568;
  font-family: var(--font-theme-two);
}

footer .footer-logo .address li svg{
  width: 18px;
  height: 18px;
  stroke: #4a5568;
}

footer .footer-logo .address li a{
  margin-inline-start: 10px;
  color: #4a5568;
  font-family: var(--font-theme-two);
  font-size: 15px;
}


footer .footer-list h5{
  font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 600;
  font-family: var(--font-theme-two);
  color: #000;
  margin: 0;
}

footer .footer-list ul.links{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 10px;
}

footer .footer-list ul.links li{
  display: block;
  width: 100%;
}

footer .footer-list ul.links li + li{
  margin-top: calc(4px + (15 - 4) * ((100vw - 320px) / (1920 - 320)));
}

footer .footer-list ul.links li a {
	display: inline-block;
	font-size: 15px;
	color: #4a5568;
	font-family: var(--font-theme-two);
	font-weight: 500;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

footer .footer-list ul.links li a:before{
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  left: 0;
  bottom: 0;
  background-color: var(--color-one);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

footer .footer-list ul.links li a::after {
	content: "";
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 5px;
	height: 5px;
	left: -13px;
	background-color: var(--color-one);
	border-radius: 100%;
	opacity: 0;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

footer .footer-list ul.links li a:hover{
  color: var(--color-one);
  margin-left: 13px;
}

footer .footer-list ul.links li a:hover:before{
  width: 100%;
}

footer .footer-list ul.links li a:hover:after{
  opacity: 1;
}

footer .footer-list ul.footer-contact{
  margin-top: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320)));
}

footer .footer-list ul.footer-contact li{
  display: block;
  margin-bottom: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
  position: relative;
  font-size: 14px;
  color: #4a5568;
}

footer .footer-list ul.footer-contact li:before{
  content: "";
  position: absolute;
  bottom: -24px;
  right: 0;
  width: calc(100% - 33px);
  border-bottom: 1px dashed #c1c1c1;
}

footer .footer-list ul.footer-contact li .footer-number{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  gap: 12px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

footer .footer-list ul.footer-contact li .footer-number svg{
  width: 18px;
  height: 18px;
  stroke: #4a5568;
}

footer .footer-list ul.footer-contact li .footer-number .text-content {
	font-size: 15px;
	line-height: 1;
	margin: 0;
	font-weight: 500;
	display: block;
	font-family: var(--font-theme-two);
	color: #4a5568;
	margin-bottom: 8px;
}

footer .footer-list ul.footer-contact li .footer-number .detail{
  display: block;
  margin-top: 6px;
  font-weight: 600;
  color: #222;
  font-family: var(--font-theme-two);
  font-size: 17px;
}


footer .footer-list ul.footer-contact li.social-app h5{
  font-size: 15px;
	line-height: 1;
	margin: 0;
	font-weight: 500;
	display: block;
	font-family: var(--font-theme-two);
	color: #4a5568;
	margin-bottom: 8px;
}
footer .footer-list ul.footer-contact li.social-app::before{
  content: unset;
}

footer .footer-list ul.footer-contact li.social-app ul li::before{
  content: unset;
}
footer .footer-list ul.footer-contact li.social-app ul li + li{
  margin-top: 10px;
}

footer .footer-list ul.footer-contact li.social-app img{
  max-height: 40px;
}

footer .sub-footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-top: 1px dashed #c1c1c1;
  padding: 30px 0;
}

footer .sub-footer .sub-footer-content{
  font-size: 15px;
	line-height: 1;
	margin: 0;
	font-weight: 500;
	font-family: var(--font-theme-two);
	color: #4a5568;
}

footer .sub-footer .social-link {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

@media (max-width: 991px) {
  footer .sub-footer {
    display: block;
    text-align: center;
  }
}

@media (max-width: 767px) {
  footer .sub-footer {
    margin-top: 20px;
  }
}

footer .sub-footer .social-link h6 {
	margin-inline-end: 10px;
}

footer .sub-footer .social-link ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

footer .sub-footer ul li {
	display: block;
}

footer .sub-footer .social-link ul li + li {
	margin-inline-start: 8px;
}

footer .sub-footer .social-link ul li a {
	display: block;
}

footer .sub-footer .social-link ul li a i {
	color: #4a5568;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
  position: relative;
  display: block;
}

footer .sub-footer .social-link ul li a:hover i {
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px);
}

footer .sub-footer .social-link ul li a:hover i{
  color: var(--color-one);
}

@media (max-width: 991px) {
  footer .sub-footer .payment {
    margin: 8px 0;
  }
  footer .sub-footer .social-link{
    justify-content: center;
  }
}

/*--------------------------------------------------------------
Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: visible;
  opacity: 0;
  right: 15px;
  bottom: 100%;
  z-index: 996;
  background: var(--color-one);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: 1s ease;
}

[dir="rtl"] .back-to-top {
  right: auto;
	left: 15px;
}

.back-to-top i {
  font-size: 21px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: var(--color-one);
  color: #fff;
}

.back-to-top.active {
  transition: 1s ease;
  right: 15px;
  bottom: 15px;
  opacity: 1;
}

[dir="rtl"] .back-to-top.active {
  right: auto;
	left: 15px;
}

.all_loges {
  position: relative;
}




.all_loges .circle_logo {
  height: 80px;
  width: 80px;
  background: #FFF;
  box-shadow: 0 16.83486px 15.71254px 0 rgba(0, 0, 0, .05);
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center
}

.all_loges .circle_logo svg {
  display: inline-block;
  vertical-align: middle
}

.all_loges .circle_logo.digital_ocean{
  background: #0080ff;
}

.all_loges .circle_logo:hover svg path {
  fill: #fff
}


.all_loges .laravel_logo:hover {
  background-color: #ff2d20
}


.all_loges .php_logo:hover {
  background-color: #5d5bc1
}


.all_loges .magento_logo:hover {
  background-color: #f26322
}



.all_loges .wordpress_logo:hover {
  background-color: #464342
}

.all_loges .woo_logo:hover {
  background-color: #7f54b3
}

.all_loges .drupal_logo {
  top: 64px;
  left: 430px;
  animation: unset
}

.all_loges .drupal_logo:hover {
  background-color: #009cde
}

.all_loges .aws_logo:hover {
  background-color: #f90
}


.all_loges .google_cloud:hover {
  background-color: #4285f4
}


.all_loges .circle_logo.digital_ocean:hover {
  background: #fff
}

.all_loges .digital_ocean:hover svg path {
  fill: #0080ff
}

.all_loges .digital_ocean svg path {
  fill: #fff;
  transition: all .3s ease
}

@media (min-width:1200px){
  .all_loges .circle_illustration {
    width: 100%
  }
  .all_loges .laravel_logo,
.all_loges .php_logo,
.magento_logo,
.all_loges .wordpress_logo,
.all_loges .woo_logo,
.all_loges .drupal_logo,
.all_loges .aws_logo,
.all_loges .digital_ocean,
.all_loges .google_cloud {
  position: absolute
}

.all_loges .laravel_logo {
  top: 187px;
  left: -26px;
  animation: floating_animation 6s ease-out infinite;
}

.all_loges .php_logo {
  top: 75px;
  left: 48px
}

.all_loges .magento_logo {
  top: -2px;
  left: 162px
}
.all_loges .wordpress_logo {
  top: -2px;
  left: 316px;
  animation: floating_animation 6s ease-out infinite
}

.all_loges .woo_logo {
  top: 186px;
  left: 504px;
  animation: floating_animation 6s ease-out infinite
}


.all_loges .circle_illustration_two {
  position: absolute;
  left: 78px;
  top: 150px
}

.all_loges .aws_logo {
  top: 200px;
  left: 385px
}

.all_loges .google_cloud {
  top: 200px;
  left: 94px
}


.all_loges .circle_logo.digital_ocean {
  height: 110px;
  width: 110px;
  padding: 18px 22px;
  transition: all .3s ease
}
.all_loges .digital_ocean {
  top: 102px;
  left: 224px
}

}

@media (max-width:1200px){
  .all_loges > svg{
    display: none;
  }
  .all_loges {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .all_loges .circle_logo{
    margin: 10px;
    padding: 15px;
  }
}

@media (max-width:550px){
  .all_loges .circle_logo{
    height: 50px;
  width: 50px;
  padding: 0;
  }
  .all_loges .circle_logo svg{
    width: 30px;
  }
}


.testimonials .testimonial-item {
	box-sizing: content-box;
	padding: 30px;
	margin: 30px 15px 0px 15px;
	min-height: 200px;
	/* box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08); */
	position: relative;
	background: #F4E5E5;
	border-radius: 15px;
}

.testimonials .testimonial-item .testimonial-img {
	width: 90px;
	border-radius: 10px;
	border: 6px solid #f4e5e5;
	float: left;
	margin: 0 10px 0 0;
}
.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 8px 0 5px 0;
  color: #111;
  font-family: var(--font-theme-two);
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
  font-family: var(--font-theme-two);
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: #fceaea;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
  color: var(--color-one);
  line-height: 20px;
}
.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  color: var(--color-one);
  line-height: 20px;

}

.testimonials .testimonial-item p {
	font-style: italic;
	margin: 15px auto 15px auto;
	font-family: var(--font-theme-two);
	font-size: 16px;
	color: #6d7371;
}


/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  border-top: 3px solid var(--color-one);
  border-bottom: 3px solid var(--color-one);
  padding: 30px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}

.contact .info i {
  font-size: 20px;
  color: var(--color-one);
  float: left;
  width: 44px;
  height: 44px;
  background: #e7f5fb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--color-one-dark);
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #7d7d7d;
}

.contact .info .email p {
  padding-top: 5px;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background: var(--color-one);
  color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: var(--color-one);
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  border-top: 3px solid var(--color-one);
  border-bottom: 3px solid var(--color-one);
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br+br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 20px;
}

.contact .php-email-form label {
  padding-bottom: 8px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: var(--color-one);
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
  background: var(--color-one);
  border: 0;
  padding: 12px 34px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
  background: var(--color-one-dark);
}


/* ========================================================

  Login , Register page , Forgot Password Page

======================================================== */

.form-account-page {
	min-height: 100vh;
	background: #fbecd8;
  display: flex;
  justify-content: center;
  padding: 30px 0;
}


.form-account-page > .container{
  display: flex;
  align-items: center;
}
.form-account-page > .container > .row{
  width: 100%;
  margin: 0;
}
.form-account-page .inner-form{
  border: 2px dashed ;
  border-color: var(--color-one);
  padding: 20px;
  border-radius: 5px;
  background: var(--color-white);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  
}

.form-account-page .inner-form .logo{
  display: flex;
  margin-bottom: 15px;
  width: 100%;
  justify-content: center;
}

.form-account-page .inner-form .logo img {
	max-height: 65px;
	width: 100%;
	max-width: min-content;
}

.form-account-page .inner-form h4 {
	font-family: var(--font-theme-two);
	font-size: 18px;
	display: block;
	text-align: center;
	color: #7d7d7d;
  margin-bottom: 20px;
}


.form-account-page .inner-form .label{
  font-family: var(--font-theme-two);
	font-size: 14px;
	display: block;
	text-align: start;
	color: #7d7d7d;
  margin-bottom: 10px;
}

.form-account-page .inner-form .input{
  font-family: var(--font-theme-two);
	font-size: 14px;
	display: block;
	text-align: start;
	color: #7d7d7d;
  height: 44px;
  padding: 10px 15px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
  background: #fff;
}

.form-account-page .inner-form .input:focus{
  border-color: var(--color-one);
}

.form-account-page .inner-form .next-link{
  font-family: var(--font-theme-two);
	font-size: 16px;
	display: block;
	text-align: center;
	color: #7d7d7d;
  margin-top: 20px;
}

.form-account-page .inner-form .next-link a{
  color: var(--color-one);
  font-family: var(--font-theme-two);
}

.form-account-page .inner-form .another-link{
  font-family: var(--font-theme-two);
	font-size: 16px;
	display: block;
	color: var(--color-one);
}